import * as React from 'react';
import CORRIDORS from '../data/corridor_info.json'

function CorridorInfo(props) {
    const data = CORRIDORS.filter((d) => d.QBC === props.corridor)[0];
    return (
        <div className='container corridor-info'>
            {/* <table className="horizontal">
                <thead>
                    <tr>
                        <th>Distance</th>
                        <th>No. of bus vehicles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label='Distance'>{data['Distance (km)']}</td>
                        <td data-label='No. of bus vehicles'>{data['No. of bus vehicles']}</td>
                    </tr>
                </tbody>
            </table> */}
            <div className='row'>
                <div className='header'>Distance:  </div>
                <div>{data['Distance (km)']} km</div>
            </div>
            <div className='row'>
                <div className='header'>No. of bus vehicles:  </div>
                <div>{data['No. of bus vehicles']}</div>
            </div>
            <div className='row'>
                <div className='header'>No. of drivers:  </div>
                <div>{data['No. of drivers']}</div>
            </div>
            <div className='row'>
                <div className='header'>Busiest Section:  </div>
                <div>{data['Busiest section']}</div>
            </div>
            <div className='row'>
                <div className='header'>Traffic Count:  </div>
                <div>{data['Traffic Count']}</div>
            </div>
            <div className='row'>
                <div className='header'>No. of ancillary services:  </div>
                <div>{data['No. of ancillary services']}</div>
            </div>
        </div>
    )
}

export default CorridorInfo;