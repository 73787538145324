import React from 'react';
import { useState, useCallback, useRef } from 'react';
import Map, {Source, Layer, MapProvider  } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import ControlPanel from './control-panel';
import RadarChart from '../components/radar-chart';
import BarChart from '../components/bar-chart';
import CorridorInfo from './corridorInfo';
// import { moveMap } from './functions/utils';

function CorridorOverviewPage() {

    // set up the initial viewState
    const [viewState, setViewState] = useState({
        longitude: 3.29,
        latitude: 6.54,
        zoom: 11
    });

    const mp = useRef();

    const [hoverInfo, setHoverInfo] = useState(null);
    const [corridor, setCorridor] = useState('Anthony- Oshodi');

    const pointLayer = {
        id: 'point',
        type: 'circle',
        paint: {
            'circle-radius': [
                'match',
                ['get', 'CorridorName'],
                corridor,
                7.5,
                5
            ],
            // 'circle-radius': 6, 
            'circle-color': [
                'match',
                ['get', 'CorridorName'],
                corridor,
                '#F9423A',
                '#58A7AF'
            ],
            'circle-stroke-color': 'black',
            'circle-stroke-width': 0.8
        },
    };

    const lrtLayer = {
        id: 'LRT',
        type: 'line',
        filter:[
            'match',
            ['get', "TransitLine"],
            'Red Line',
            true,
            'Blue Line',
            true,
            /*other*/ false
        ],
        paint: {
            'line-color': [
                'match',
                ['get', "TransitLine"],
                'Yellow Line',
                '#D9BA1C',
                'Yellow Line - Deprecated in favor of Purple Line',
                '#D9BA1C',
                'Purple Line',
                '#A020F0',
                'Purple Line (Drop)',
                '#A020F0',
                'Orange Line',
                '#FFA500',
                'Green Line',
                '#008000',
                'Green Line (Drop)',
                '#008000',
                'Red Line',
                '#F9423A',
                'Blue Line',
                '#001E60',
                'Blue Line Phase 3',
                '#001E60',
                /*other*/ '#808080'
            ],
            'line-width': 2,
        }
    };

    const lrtLayer_future = {
        id: 'LRT',
        type: 'line',
        filter:[
            'match',
            ['get', "TransitLine"],
            'Red Line',
            false,
            'Blue Line',
            false,
            /*other*/ true
        ],
        paint: {
            'line-color': [
                'match',
                ['get', "TransitLine"],
                'Yellow Line',
                '#D9BA1C',
                'Yellow Line - Deprecated in favor of Purple Line',
                '#D9BA1C',
                'Purple Line',
                '#A020F0',
                'Purple Line (Drop)',
                '#A020F0',
                'Orange Line',
                '#FFA500',
                'Green Line',
                '#008000',
                'Green Line (Drop)',
                '#008000',
                'Red Line',
                '#F9423A',
                'Blue Line',
                '#001E60',
                'Blue Line Phase 3',
                '#001E60',
                /*other*/ '#808080'
            ],
            'line-width': 2,
            'line-dasharray': [2, 2]
        }
    };

    const brtLayer = {
        id: 'BRT',
        type: 'line',
        paint: {
            'line-opacity': 0.5, 
            'line-color': '#000000',
            'line-width': 1.5
        }
    };

    const onHover = useCallback(event => {
        const {
            features,
            point: {x, y},
        } = event;
        const hoveredFeature = features && features[0];
        setHoverInfo(hoveredFeature && {feature: hoveredFeature, x, y});
    }, [])

    

    const onClick = useCallback(e => {
        const {
            features,
            point: {x, y},
        } = e;

        const clickedFeature = features && features[0];
        if (clickedFeature) {
            setCorridor(clickedFeature && clickedFeature.properties.CorridorName); 
            const lon = clickedFeature.properties.esrignss_longitude;
            const lat = clickedFeature.properties.esrignss_latitude;
            // console.log('clicked');
            // console.log(mp.current)
            // console.log([lon, lat])
            mp.current.flyTo({center: [lon, lat], duration: 2000, zoom: 13})
        }

    }, [mp])

    return (
        <MapProvider>
            <div className='container overview'>
                <div className='row'>
                    <ControlPanel corridorUpdate={setCorridor} corridor={corridor}></ControlPanel>
                </div>
                <div className='row'>
                    <div className='col-sm-8' style={{border: '1px solid black'}}>
                        <Map
                            id='overviewMap'
                            ref={mp}
                            {...viewState}
                            onMove={evt => setViewState(evt.viewState)}
                            // style={{ width: '100%', height: 'calc(60vh - 5rem)' }}
                            mapStyle='https://tiles.basemaps.cartocdn.com/gl/positron-gl-style/style.json'
                            interactiveLayerIds={['point', 'LRT', 'BRT']}
                            onMouseMove={onHover}
                            onMouseDown={onClick}
                        >   
                            {/* <ControlPanel onSelectCorridor={ onSelectCorridor } /> */}
                            <Source id='LRT-lines-future' type='geojson' data={"./assets/LRT_lines.geojson"}>
                                <Layer {...lrtLayer_future}/>
                            </Source>
                            <Source id='LRT-lines' type='geojson' data={"./assets/LRT_lines.geojson"}>
                                <Layer {...lrtLayer}/>
                            </Source>
                            <Source id='BRT-lines' type='geojson' data={"./assets/BRT_lines.geojson"}>
                                <Layer {...brtLayer}/>
                            </Source>
                            <Source id='bus-stops' type='geojson' data={"./assets/bus_stops.geojson"}>
                                <Layer {...pointLayer} onClick={(e) => console.log(e)}/>
                            </Source>
                            
                            {hoverInfo && (
                                <div className='tooltip' style={{left: hoverInfo.x, top: hoverInfo.y}}>
                                    <div>{hoverInfo.feature.source === 'bus-stops' ? 'Stop Name: ' + hoverInfo.feature.properties.stop_name : ( hoverInfo.feature.source === 'LRT-lines' ? 'LRT Line: ' + hoverInfo.feature.properties.TransitLine : 'BRT Line: ' + hoverInfo.feature.properties.name) } </div>
                                </div>
                            )}
                            <CorridorInfo corridor={corridor}/>
                        </Map>
                        
                    </div>
                    <div className='col-sm-4' style={{border: '1px solid black', height: 'calc(80vh)'}}>
                        <div className='row'>
                            <div className='col-sm-12' style={{borderBottom: '1px solid black' }}>
                                <BarChart corridor={corridor}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-2'></div>
                            <div className='col-sm-8'>
                                <RadarChart corridor={corridor}/>
                            </div>
                            <div className='col-sm-2'></div>
                        </div>
                    </div>
                </div>
            </div>
        </MapProvider>
    )
}

export default CorridorOverviewPage;