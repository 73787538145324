// import { useCallback } from "react";
// import { MapRef } from "react-map-gl";

// const onSelectCorridor = useCallback(({ref, longitude, latitude}: {ref: MapRef, longitude: number, latitude: number}) => {
//     ref.flyTo({center: [longitude, latitude], duration: 2000});
// }, [])

const moveMap = function(ref, coordinates) {
    // console.log(coordinates)
    // console.log(ref);
    ref.flyTo({center: coordinates, duration: 2000, zoom: 13});
}

export { moveMap }