import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import HomePage from './home/HomePage';
import CorridorOverviewPage from './corridor-overview/CorridorOverviewPage';
import BusPassengerDemandPage from './bus-passenger-demand/BusPassengerDemandPage';

function App() {
  return (
    <Router>
      <header className="sticky">
        <span className="logo">
          <img src="/assets/CPCS_M365_logo.png" alt="logo" width="50" height="50" style={{verticalAlign: 'middle'}}/>
        </span>
        <NavLink key={'nav-home'} to="/"  className="button rounded">
          <span className="icon-home"></span>
          Home
        </NavLink>
        <NavLink key={'nav-corridor'} to="/corridor-overview" className="button rounded">
          Corridor Overview
        </NavLink>
        <NavLink kye={'nav-bus'} to="/bus-passenger-demand" className="button rounded">
          Bus Passenger Demand
        </NavLink>
        </header>
        <div className='container'>
          <Routes>
            <Route key={'home-page'} path="/" element={<HomePage />}/>
            <Route key={'corridor-overview'} path="/corridor-overview" element={<CorridorOverviewPage />}/>
            <Route key={'bus-passenger-demand'} path="/bus-passenger-demand" element={<BusPassengerDemandPage />}/>
          </Routes>
        </div>
    </Router>  
  );
}

export default App;
