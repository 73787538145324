const vehicles = [
    {
        imgURL:
          "/images/danfoes.jpg",
        imgAlt: "Danfoes"
      },
    {
        imgURL:
          "/images/koropes.jpg",
        imgAlt: "Koropes"
    },
    {
        imgURL:
          "/images/keke.jpg",
        imgAlt: "Keke Napep"
      },
    {
        imgURL:
          "/images/molues.png",
        imgAlt: "Molues"
    },
    {
        imgURL:
          "/images/motocycle_taxi.jpg",
        imgAlt: "Motocycle Taxi"
    },
];

export default vehicles;