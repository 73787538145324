import React, {memo } from 'react';
import { useMap } from 'react-map-gl/maplibre';
import { moveMap } from './functions/utils';

import CORRIDORS from '../data/corridors.json';

function ControlPanel(props) {
    const { overviewMap } = useMap();
    
    function onClick(corr) {
        moveMap(overviewMap, corr.geometry.coordinates);
        props.corridorUpdate(corr.properties.CorridorName);    
    }
    return (
        <div className='control-panel' id='corridor-panel'>
            {CORRIDORS.features.map((corr, index) => (
                <button
                    // name='corridor'
                    key={index}
                    id={`corridor-${corr.properties.CorridorName}`}
                    className={corr.properties.CorridorName === props.corridor ? 'active' : 'inactive'}
                    // onClick={() => moveMap(overviewMap, corr.geometry.coordinates)}
                    onClick={() => onClick(corr)}
                >{corr.properties.CorridorName === 'Ketu - Mile 12- Alapere' ? 'Ketu - Alapere - Akanimodo': corr.properties.CorridorName}</button>
            ))}
        </div>
    )
}

export default memo(ControlPanel)