const images = [
    {
      imgURL:
        "/images/corridor_overview_1.png",
      imgAlt: "Overview"
    },
    {
      imgURL:
        "/images/corridor_overview_2.png",
      imgAlt: "Overview"
    },
  ];
  
  export default images;