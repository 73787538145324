import React, { useRef, useState } from 'react';
import PieChart from '../components/pie-chart';
import SankeyChart from '../components/sankey-chart';

import CORRIDORS from '../data/corridors.json';
import CORRDIRS from '../data/corridor_directions.json'

function BusPassengerDemandPage () {

    const [corr, setCorr] = useState("anthony_oshodi");
    const transit_modes = ['Walking', 'Taxi', 'Keke', 'Car Drop Off', 'Another Bus', 'Okada', 'Cycling', 'Unspecified']
    const genderPieRef = useRef(null);
    const agePieRef = useRef(null);
    const employmentPieRef = useRef(null);
    const incomePieRef = useRef(null);
    const householdPieRef = useRef(null);
    const purposePieRef = useRef(null);
    // const sankeyRef = useRef(null);

    const H = window.innerHeight;
    const W = window.innerWidth;
    function onChange(evt) {
        //console.log(evt.target.value);
        let corridor = corr;
        const updatedValue = evt.target.value;
        
        if (updatedValue === 'Iju Ishaga-Abule Egba') {
            corridor = "iju_ishaga"
        }
        else if (updatedValue === "Iyana Iba-Igando") {
            corridor = "iyana_iba"
        }
        else if (updatedValue === "Iyana Ipaja-Ayobo") {
            corridor = "iyana_ipaja"
        }
        else if (updatedValue === "Ketu - Mile 12- Alapere") {
            corridor = "ketu_alapere"
        }
        else if (updatedValue === "Ojuelegba-Idi Araba -Ilasamaja") {
            corridor = "ojuelegba"
        }
        else if (updatedValue === "Onipanu - Oshodi") {
            corridor = "onipanu_oshodi"
        }
        else if (updatedValue === "Yaba-Lawanson-Cele") {
            corridor = "yaba_lawanson"
        }
        else if (updatedValue === 'Anthony- Oshodi') {
            corridor = "anthony_oshodi"
        }

        setCorr(corridor);

        genderPieRef.current.change(corridor);
        agePieRef.current.change(corridor);
        employmentPieRef.current.change(corridor);
        incomePieRef.current.change(corridor);
        householdPieRef.current.change(corridor);
        purposePieRef.current.change(corridor);
    }

    return (
        <div className='container overview'>
            <div className='row'>
                <select name='corridor-selector' onChange={onChange}>
                    {CORRIDORS.features.map((corr, index) => (
                        <option key={"corridor-option" + String(index)} value={corr.properties.CorridorName}>{corr.properties.CorridorName === 'Ketu - Mile 12- Alapere' ? 'Ketu - Alapere - Akanimodo': corr.properties.CorridorName}</option>
                    ))}
                </select>
            </div>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <PieChart ref={genderPieRef} size={H/3} url={"/assets/gender.json"} title={'Gender'}></PieChart>
                        </div>
                        <div className='col-sm-4'>
                            <PieChart ref={employmentPieRef} size={H/3} url={"/assets/employment_status.json"} title={'Employment Status'}></PieChart>
                        </div>
                        <div className='col-sm-4'>
                            <PieChart ref={incomePieRef} size={H/3} url={"/assets/income.json"} title={'Income'}></PieChart>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <PieChart ref={agePieRef} size={H/3} url={"/assets/age.json"} title={'Age'}></PieChart>
                        </div>
                        <div className='col-sm-4'>
                            <PieChart ref={householdPieRef} size={H/3} url={"/assets/household_size.json"} title={'Household Size'}></PieChart>
                        </div>
                        <div className='col-sm-4'>
                            <PieChart ref={purposePieRef} size={H/3} url={"/assets/trip_purpose.json"} title={'Trip Purpose'}></PieChart>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <SankeyChart url={"/assets/modes_by_direction.csv"} width={W/2 - 10} height={H/4 * 3} corridor={corr} directions={CORRDIRS.filter((c) => (c.corridor === corr))[0].directions} modes={transit_modes}/>
                </div>
            </div>
        </div>
    )
}

export default BusPassengerDemandPage;