import React from 'react';

import PROJECT from '../data/project_info.json';
import images from "../data/images";
import vehicles from "../data/vehicle_images"

import './homePage.css'

function HomePage() {
    const about = PROJECT.about;
    const geographic_scope = PROJECT.geographic_scope;
    const corridors = PROJECT.corridors;
    const second_paragraph = PROJECT.geographic_scope_second_part;

    return (
        
        <div className='container'>
            <h1>About the Project</h1>
            <div className='row'>
                <div className='col-sm-12'>
                    {about}
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='container'>
                        <div className='row'>
                            <h2>Geographic Scope</h2>
                            <div className='col-sm-12'>
                                {geographic_scope}
                            </div>
                            <ol style={{'paddingLeft': 100}}>
                                {corridors.map((c, index) => (
                                    <li key={'qbc-' + String(index)}>{c}</li>
                                ))}
                            </ol>
                            <div className='col-sm-12'>
                                {second_paragraph}
                            </div>
                            
                        </div>
                    </div>
                    <div className='container'>
                        <h2>Vehicles</h2>
                        <div className='row'>
                            {vehicles.map((veh, index) => {
                                return (
                                    <div className='col-sm-2' key={index}>
                                        <img src={veh.imgURL} alt={veh.imgAlt } />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='container'>
                        <img src={images[0].imgURL} alt={images[0].imgAlt}></img>
                        {/* <CustomCarousel>
                            {images.map((image, index) => {
                                return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
                            })}
                        </CustomCarousel> */}
                    </div>
                    <div className='container credits'>
                        <div className='row'>
                            <span className='footer-title'>Licensing</span>
                            <span>Data is licensed under Open Data Commons Open Database License (ODbL).</span>
                        </div>
                        <div className='row'>
                            <span className='footer-title'>Authorship and Acknowledgement</span> 
                            <span>Data collected by CPCS Transcom Ltd., Geotrans Ltd and Lagos Metropolitan Area Transport Authority.</span>
                        </div>
                        <div className='row'>
                            <span className='footer-title'>Data Status</span>
                            <span>This is data is up to date as of December 2023.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;