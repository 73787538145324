import React, { useEffect, useState} from "react";
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal, sankeyJustify } from 'd3-sankey'

function SankeyChart(
    props
) {
    const corr = props.corridor;
    const directions = props.directions;
    // console.log(directions);
    const [direction, setDirection] = useState(directions[0]);
    // console.log(d3.schemeCategory10);
    const color = d3.scaleOrdinal(d3.schemeCategory10).domain(props.modes);

    const chart = (data) => {
        const width = props.width;
        const height = props.height;
        d3.select('#sankey-chart').selectAll('*').remove();
        const svg = d3.select('#sankey-chart')
            .attr("viewBox", [0, 0, width, height])
            .on('mouseout', () => {
                d3.selectAll(`path`)
                    .attr('stroke-opacity', 0.1);
            });
        
        const sankeyGenerator = sankey()
            .nodeWidth(15)
            .nodePadding(10)
            .extent([[10, 5], [width - 10, height - 5]])
            .nodeId((node) => node.name)
            .nodeAlign(sankeyJustify) // d3.sankeyLeft, etc.
        
        // console.log(data);
        const {nodes, links} = sankeyGenerator({
            nodes: data.nodes.map(d => Object.assign({}, d)),
            links: data.links.map(d => Object.assign({}, d))
        });
        
        // const color = d3.scaleOrdinal(d3.schemeCategory10);

        // Creates the rects that represent the nodes.
        const rect = svg.append("g")
            .attr("stroke", "#000")
            .selectAll()
            .data(nodes)
            .join("rect")
            .attr("x", d => d.x0)
            .attr("y", d => d.y0)
            .attr("height", d => d.y1 - d.y0)
            .attr("width", d => d.x1 - d.x0)
            .attr("fill", d => color(d.category));
        
        // Adds a title on the nodes
        rect.append('title')
            .text(d => `${d.name}`);

        const link = svg.append("g")
            .attr("fill", "none")
            .attr("stroke-opacity", 0.5)
            .selectAll()
            .data(links)
            .join("g")
            .style("mix-blend-mode", "multiply");

        link.append("path")
            .attr("d", sankeyLinkHorizontal())
            .attr("stroke", (d) => color(d.source.category))
            .attr("stroke-width", d => Math.max(1, d.width));

        link.append("title")
        .text(d => `${d.source.name.split('-')[0]} → ${d.target.name.split('-')[0]}: ${d.value}`);

        // Adds labels on the nodes.
        svg.append("g")
            .selectAll()
            .data(nodes)
            .join("text")
                .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
                .attr("y", d => (d.y1 + d.y0) / 2)
                .attr("dy", "0.35em")
                .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
                .text(d => d.category);
        
        return svg.node();
    }

    useEffect(() => {
        setDirection(directions[0])
    }, [corr])

    useEffect(() => {
        d3.csv(props.url).then(function(data) {
            // console.log(data);
            // console.log(direction);
            const links = data.filter((d) => d.Corridor === corr && d.direction === direction);
            links.forEach(function(d) {
                d['value'] = +d['value'];
            })
            const nodes = Array.from(new Set(links.flatMap(l => [l.source, l.target])), name => ({name, category: name.replace(/-.*/, "")}));
            chart({nodes, links});
        });
        
    }, [direction]);

    return (
        <div className='sankey-container container'>
            <div>
                <span>Transit Modes at the Origin and Destination</span>
            </div>
            <div id='direction-panel' className='row'>
                {directions.map((dir, index) => (
                    <div key={'input' + String(index)}>
                        <input type='radio' value={dir} checked={ dir === direction } onClick={() => setDirection(dir)} onChange={() => {}}></input>
                        <label >From <span>{dir}</span></label>
                    </div>
                ))}
            </div>
            <svg id='sankey-chart' height={props.height} width={props.width}></svg>
        </div>
        
    )
}

export default SankeyChart;