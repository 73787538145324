import React, { useEffect, forwardRef, memo } from 'react';
import  * as d3 from 'd3';
// import { filter } from '@observablehq/plot';


const PieChart = forwardRef(function PieChart(
    props,
    ref
) {
    
    const inputRef = ref;
    const size = props.size;
    const textID = props.title.replace(' ', '');
    // console.log(props.size);
    // console.log(props.url);
    const chart = (inputJSON) => {
        const data = inputJSON.records;
        // console.log(data)
        const categories = inputJSON.categories;
        // console.log(categories);
        const height = Math.min(500, size/2);
        const outerRadius = height / 2 - 10;
        const innerRadius = outerRadius * 0.5

        const color = d3.scaleOrdinal(d3.schemeCategory10).domain(categories);
        // console.log(color)
        //const svg = d3.create("svg").attr("viewBox", [-size/2, -height/2, size, height]);
        const svg = d3.select(inputRef?.current).attr("viewBox", [-size/4, -height/2, size, height]);
        
        // const visibility = (value) => value === 0 ? 'collapse' : 'visible';

        // const center = svg.append('g')
        //     .attr('transform', `translate(0, 0)`);
        // const center = svg.append('g')
        // .attr('transform', `translate(${height/2}, ${height/2})`);
        // const centerSvg = center.append('circle')
        //     .attr('fill', 'transparent')
        //     .attr('r', outerRadius - innerRadius - 3);

        // const text = center.append('text')
        //     .attr('text-anchor', 'middle')
        //     .attr('text-align', 'center')
        //     .attr('font-size', '0.5rem')
        //     .attr('fill', '#000')
        //     .attr('id', `text-${textID}`)

        const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        const pie = d3.pie().sort(null).value((d) => d['anthony_oshodi']);

        const path = svg.datum(data).selectAll("path")
            .data(pie)
            .join("path")
            .attr("fill", (d, i) => color(categories[i]))
            .attr("d", arc)
            .each(function(d) {
                this._current = d;
            })
            .on("mousemove", handleMoveOver)
            .on("mouseout", handleMoveOut)
            // .append('title').text((d, i) => categories[i]);

        // let legendPadding = 5;
        // svg.select('.legend-group').remove();
        // console.log(svg.select(".legend-group"));
        const legendBox = svg.append('g')
            .attr("class", "legend-group")
            .attr("transform", `translate(${size/6 + innerRadius}, ${-80})`);
        // console.log('test');
        
            // (0.0001) * innerRadius}
        function getFilteredCategories(cates, corridor) {
            let filteredCategories = [...cates]
            // console.log(filteredCategories);
            let indices2remove = [];
            for (let [i, v] of data.entries()) {
                // console.log(v);
                if (v[corridor] === 0) {
                    indices2remove.push(i);
                }
            }
            // console.log(indices2remove);
            for (let idx of indices2remove) {
                filteredCategories.splice(idx, 1);
            }
            return filteredCategories
        }
        let filteredCategories = getFilteredCategories(categories, 'anthony_oshodi');
        // let indices2remove = [];
        // for (let [i, v] of data.entries()) {
        //     console.log(v);
        //     if (v['anthony_oshodi'] === 0) {
        //         indices2remove.push(i);
        //     }
        // }
        // // console.log(indices2remove);
        // for (let idx in indices2remove) {
        //     filteredCategories.splice(idx, 1);
        // }
        // console.log(filteredCategories)
        function createLegendItems(cates) {
            legendBox.selectAll(".legend").remove();
            let legend = legendBox.selectAll(".legend")
                .data(cates).enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", (d, i) => `translate(${0}, ${i * 20 + 20})`);
        
            let displacement = "0.4em"
            legend.append("circle")
                .attr("class", "legend-color")
                .attr("r", displacement)
                // .attr('visibility', (d, i) => visibility(data[i]['anthony_oshodi']))
                .style("fill", (d, i) => color(categories[i]));
            
            legend.append("text")
                .attr("class", "legend-text")
                .attr("x", "1em")
                .attr("y", displacement)
                // .attr('visibility', (d, i) => visibility(data[i]['anthony_oshodi']))
                .style("font", "12px sans-serif")
                .style("text-anchor", "start")
                .style("fill", "#000000")
                .text((d, i) => cates[i]);
        }

        createLegendItems(filteredCategories);
        // let legend = legendBox.selectAll(".legend")
        //     .data(filteredCategories).enter()
        //     .append("g")
        //     .attr("class", "legend")
        //     .attr("transform", (d, i) => `translate(${0}, ${i * 20 + 20})`);
        
        // let displacement = "0.4em"
        // legend.append("circle")
        //     .attr("class", "legend-color")
        //     .attr("r", displacement)
        //     // .attr('visibility', (d, i) => visibility(data[i]['anthony_oshodi']))
        //     .style("fill", (d, i) => color(categories[i]));
        
        // legend.append("text")
        //     .attr("class", "legend-text")
        //     .attr("x", "1em")
        //     .attr("y", displacement)
        //     // .attr('visibility', (d, i) => visibility(data[i]['anthony_oshodi']))
        //     .style("font", "12px sans-serif")
        //     .style("text-anchor", "start")
        //     .text((d, i) => categories[i]);

        function change(value) {
            pie.value((d) => d[value]);
            path.data(pie); // compute the new angles
            path.transition().duration(750).attrTween("d", arcTween); // redraw the arcs
            let filteredCategories = getFilteredCategories(categories, value);
            createLegendItems(filteredCategories);
        }

        function arcTween(a) {
            const i = d3.interpolate(this._current, a);
            this._current = i(0);
            return (t) => arc(i(t));
        }

        function handleMoveOver(d, i) {
            d3.select(this)
                .attr("stroke", "#fff")
                .attr("stroke-width","3px");
            const end = d.target.__data__.endAngle;
            const start = d.target.__data__.startAngle
            const t = d3.select(`#text-${textID}`);
            
            t.html('');
            // d3.select(`#text-${textID}`)
            //     .html(`${(end - start) / (2 * Math.PI)}%`);
            t.html(`${((end - start) / Math.PI * 50).toFixed(2) }%`)
        }

        function handleMoveOut(d, i) {
            d3.select(this)
                .attr("stroke-width","0px");
            d3.select(`#text-${textID}`)
                .html("");
        }

        return Object.assign(svg.node(), {change});
    }
    useEffect(() => {
        
        // const c = chart();
        // console.log(props.url);
        d3.json(props.url).then(function(data) {
            // console.log(data);
            chart(data);
        })
        // chart()
    }, );
    

    return (
        <div className='piechart-container'>
            <div>{props.title}</div>
            <svg ref={inputRef} height={size} width={size}></svg>
        </div>
        
    )
})

export default memo(PieChart);