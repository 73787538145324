import React, { useRef, useEffect, useState } from 'react';
import * as Plot from '@observablehq/plot';

import TRAFFIC from '../data/traffic_count.json'

function BarChart(props) {
    const ref = useRef(null);
    const [direction, setDirection] = useState('A-B')

    function getCorridor(value) {
        const s = value.split('-')[0].trim().toLowerCase();
        if (s === 'anthony') {
            return ['ANTHONY OSHODI', 'Anthony', 'Oshodi']
          }
          else if (s === 'iju ishaga') {
            return ['IJU ISHAGA ABULE EGBA', 'Iju Ishaga', 'Abule Egba']
          }
          else if (s === 'iyana iba') {
            return ['IGANDO IYANA IBA', 'Igando', 'Iyana Iba']
          }
          else if (s === 'iyana ipaja') {
            return ['IYANA IPAJA AYOBO','Iyana Ipaja', 'Ayobo']
          }
          else if (s === 'ketu') {
            return ['KETU ALAPERE', 'Ketu', 'Alapere']
          }
          else if (s === 'ojuelegba') {
            return ['OJUELEGBA IDI ARABA ILASAMAJA', 'Ojueleba', 'Idi Araba']
          }
          else if (s === 'onipanu') {
            return ['ONIPANU OSHODI', 'Onipanu', 'Oshodi']
          }
          else if (s === 'yaba'){
            return ['YABA - LAWANSON-CELE', 'Yaba', 'Cele']
          }
          else {
            return ['No corridor selection', 'Start', 'End']
          }
    }

    const [cor, a, b] = getCorridor(props.corridor);
    const data = TRAFFIC.filter(({Corridor, Direction}) => Corridor === cor && Direction === direction);

    useEffect(() => {
        const plot = Plot.plot({
            height: 400,
            x: {axis: null},
            y: {tickFormat: "s", grid: true},
            color: {scheme: "spectral", legend: true},
            marks: [
              Plot.barY(data, {
                x: "Type",
                y: "Traffic",
                fill: "Type",
                fx: "Location",
                sort: {x: null, color: null, fx: {value: "-y", reduce: "sum"}},
              }),
              Plot.text(data, {x: "Type", y:"Traffic", fx:"Location", text: (d) => (d.Traffic).toFixed(0), dy: -6, lineAnchor: "bottom"}),
              Plot.ruleY([0]),
            ],
            facet: {
              label: null
            }
        })
        ref.current?.append(plot);
        return () => plot.remove();
    }, [data])
    
    return (
        <div className='container'>
            <div>
                <input type='radio' value='A-B' checked={ direction === 'A-B'} onClick={() => setDirection('A-B')} onChange={() => {}}></input>
                <label>From <span>{a}</span> to <span>{b}</span></label>
                <input type='radio' value='A-B' checked={ direction === 'B-A'} onClick={() => setDirection('B-A')} onChange={() => {}}></input>
                <label>From <span>{b}</span> to <span>{a}</span></label>
            </div>
            <div ref={ref}></div>
        </div>
    )
}

export default BarChart;