import React, { useRef, useEffect } from 'react';
import * as Plot from '@observablehq/plot';
import  * as d3 from 'd3';
import './radar-chart.css';

import QUALITY from '../data/quality_metrics.json';

function RadarChart(props) {

    //const ref = useRef<SVGSVGElement>(null);
    const ref = useRef(null);
    
    function getCorridor(value) {
        const s = value.split('-')[0].trim().toLowerCase();
        // console.log(s);
        if (s === 'anthony') {
          return 'anthony_oshodi'
        }
        else if (s === 'iju ishaga') {
          return 'iju_ishaga'
        }
        else if (s === 'iyana iba') {
          return 'iyana_iba'
        }
        else if (s === 'iyana ipaja') {
          return 'iyana_ipaja'
        }
        else if (s === 'ketu') {
          return 'ketu_alapere'
        }
        else if (s === 'ojuelegba') {
          return 'ojuelegba'
        }
        else if (s === 'onipanu') {
          return 'onipanu_oshodi'
        }
        else if (s === 'yaba'){
          return 'yaba_lawanson'
        }
        else {
          return 'No corridor selection'
        }
    }
    const cor = getCorridor(props.corridor);
    const data = Object.values(QUALITY).flatMap(({corridors, ...values}) => 
        Object.entries(values).map(([key, value]) => ({ corridors, key, value })).filter(({corridors, key, value}) => corridors === cor)
    );
    // console.log(qualityMetrics);
    // const [data, setData] = useState(qualityMetrics);
    // const filteredData = qualityMetrics.filter(({corridors, key, value}) => corridors === cor)
    // setData(filteredData)
    useEffect(() => {
        const longitude = d3.scalePoint(new Set(Plot.valueof(data, "key")), [180, -180]).padding(0.5).align(1);
        
        const plot = Plot.plot({
            width: ref.current?.offsetWidth,
            projection: {
              type: "azimuthal-equidistant",
              rotate: [0, -90],
              // Note: 0.625° corresponds to max. length (here, 1), plus enough room for the labels
              domain: d3.geoCircle().center([0, 90]).radius(1.5)()
            },
            color: { legend: false },
            marks: [
              // grey discs
              Plot.geo([1, 0.75, 0.5, 0.25], {
                geometry: (r) => d3.geoCircle().center([0, 90]).radius(r)(),
                stroke: "black",
                fill: "black",
                strokeOpacity: 0.3,
                fillOpacity: 0.03,
                strokeWidth: 0.5
              }),
          
              // white axes
              Plot.link(longitude.domain(), {
                x1: longitude,
                y1: 90 - 1,
                x2: 0,
                y2: 90,
                stroke: "white",
                strokeOpacity: 0.5,
                strokeWidth: 2.5
              }),
          
              // tick labels
              Plot.text([0.25, 0.5, 0.75, 1], {
                x: 45,
                y: (d) => 90 - d,
                dx: 2,
                textAnchor: "start",
                text: (d) => {
                    if (d === 0.25) {
                        return 'Poor'
                    }
                    else if (d === 0.5) {
                        return 'Fair'
                    }
                    else if (d === 0.75) {
                        return 'Good'
                    }
                    else {
                        return 'Excellent'
                    }
                },
                fill: "currentColor",
                stroke: "white",
                fontSize: 8,
              }),
          
              // axes labels
              Plot.text(longitude.domain(), {
                x: longitude,
                y: 90 - 1.2,
                text: Plot.identity,
                lineWidth: 5,
                fontWeight: 'bold'
              }),
          
              // areas
              Plot.area(data, {
                x1: ({ key }) => longitude(key),
                y1: ({ value }) => 90 - value,
                x2: 0,
                y2: 90,
                fill: "#F9423A",
                stroke: "#F9423A",
                curve: "cardinal-closed"
              }),
          
              // points
              Plot.dot(data, {
                x: ({ key }) => longitude(key),
                y: ({ value }) => 90 - value,
                fill: "#F9423A",
                stroke: "white"
              }),
          
              // interactive labels
              Plot.text(
                data,
                Plot.pointer({
                  x: ({ key }) => longitude(key),
                  y: ({ value }) => 90 - value,
                  text: (d) => `${d.value.toFixed(2)}`,
                  textAnchor: "start",
                  dx: 4,
                  fill: "#F9423A",
                  stroke: "white",
                  maxRadius: 10
                })
              ),
          
              // interactive opacity on the areas
            //   () =>
            //     svg`<style>
            //         g[aria-label=area] path {fill-opacity: 0.1; transition: fill-opacity .2s;}
            //         g[aria-label=area]:hover path:not(:hover) {fill-opacity: 0.05; transition: fill-opacity .2s;}
            //         g[aria-label=area] path:hover {fill-opacity: 0.3; transition: fill-opacity .2s;}
            //     `
            ]
        })
        ref.current?.append(plot)
        return () => plot.remove();
    }, [data])
    

    return(
        <div className='container'>
            {/* <div>{props.corridor}</div> */}
            <div ref={ref}></div>
        </div>
    )
}

export default RadarChart